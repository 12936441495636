import { useEffect, createContext, useReducer } from 'react';
// hooks
import useAuth from '../hooks/useAuth';
// utils
import axios from '../utils/axios';
import * as Sentry from '@sentry/nextjs';

// ----------------------------------------------------------------------

const initialState = {
  company: null,
  jobPosts: null,
  candidate: null,
  crews: [],
  activeCrews: [],
};

const handlers = {
  SET_JOBPOSTS: (state, action) => {
    const { jobPosts } = action.payload;
    return {
      ...state,
      jobPosts,
    }
  },
  SET_CANDIDATE: (state, action) => {
    const { candidate } = action.payload;
    return {
      ...state,
      candidate,
    }
  },
  SET_CREWS: (state, action) => {
    const { crews } = action.payload;
    return {
      ...state,
      crews,
    }
  },
  SET_ACTIVE_CREWS: (state, action) => {
    const { activeCrews } = action.payload;
    return {
      ...state,
      activeCrews,
    }
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const CompanyContext = createContext({
  ...initialState,
  getCompany: () => Promise.resolve(),
  getCrews: () => Promise.resolve(),
  // getJobPosts: () => Promise.resolve(),
  // getCandidate: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

function CompanyProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user } = useAuth();

  useEffect(() => {
    const initialize = async () => {
      await getCrews();
    };

    initialize();
  }, [user]);

  const getCompany = async () => {
    const responseMe = await axios.get('api/accounts/me/employee/');
    dispatch({
      type: 'SET_COMPANY',
      payload: {
        company: responseMe.data?.employee?.company
      },
    });
    return responseMe.data?.employee?.company;
  };

  const getCrews = async () => {
    try {
      if (!user) return;
      if (!user.employee) return;
      const companyId = user.employee.company[0].id;
      const response = await axios.get(`/api/company/${companyId}/crews/`);
      const crewsData = response.data.results;
      const activeCrewsData = crewsData?.filter(crew => crew.is_active === true) || [];
      dispatch({
        type: 'SET_CREWS',
        payload: {
          crews: crewsData
        },
      });
      dispatch({
        type: 'SET_ACTIVE_CREWS',
        payload: {
          activeCrews: activeCrewsData
        },
      });
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  return (
    <CompanyContext.Provider
      value={{
        ...state,
        getCompany,
        getCrews,
        // getJobPosts,
        // getCandidate,
      }}
    >
      {children}
    </CompanyContext.Provider>
  )
}

export { CompanyContext, CompanyProvider };
