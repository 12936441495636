// ----------------------------------------------------------------------

export default function Badge() {
  return {
    MuiBadge: {
      styleOverrides: {
        dot: {
          minWidth: 4,
          width: 4,
          height: 4,
        },
      },
    },
  };
}
