// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 92,
  MAIN_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 38,
  DASHBOARD_ITEM_SUB_HEIGHT: 38,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 18,
  NAVBAR_ITEM_HORIZONTAL: 18,
};

// SETTINGS
// ----------------------------------------------------------------------

export const cookiesExpires = 3;

export const cookiesKey = {
  themeMode: 'themeMode',
  themeDirection: 'themeDirection',
  themeColorPresets: 'themeColorPresets',
  themeLayout: 'themeLayout',
  themeStretch: 'themeStretch',
};

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'purple',
  themeLayout: 'horizontal',
  themeStretch: false,
};

export const WHATTIME_JOB_POST_CALENDAR_ID = process.env.WHATTIME_JOB_POST_CALENDAR_ID;
export const WHATTIME_3WAY_CALENDAR_ID = process.env.WHATTIME_3WAY_CALENDAR_ID;
export const WHATTIME_OPPOSE_CALENDAR_ID = process.env.WHATTIME_OPPOSE_CALENDAR_ID;
export const WHATTIME_REGISTER_CALENDAR_ID = process.env.WHATTIME_REGISTER_CALENDAR_ID;

export const EVENT_ID = {
  bizLogin: 'biz-login',  // 직원 로그인 버튼
  bizRegister: 'biz-register',  // 직원 가입 버튼
  bizRegisterCompany: 'biz-register-company',  // 직원 이름, 전화번호, 회사이름 저장 버튼
  bizJobPost: 'biz-job-post',  // 직원 사이드잡 모집 공고 등록 버튼
  bizJobPostEdit: 'biz-job-post-edit',  // 직원 사이드잡 모집 공고 수정 버튼
  bizJobPostSetFit: 'biz-job-post-set-fit',  // 직원 사이드잡 모집 공고 핏 설정 버튼 (2/3)
  bizJobPostSearchRequest: 'biz-job-post-search-request',  // 직원 사이드잡 모집 공고 서치 요청 버튼 (3/3)
  bizSendResetPasswordEmail: 'biz-send-reset-password-email',  // 직원 비밀번호 재설정 이메일 보내기 버튼
  bizResetPassword: 'biz-reset-password',  // 직원 비밀번호 재설정 버튼
  bizAcceptOffer: 'biz-accept-offer',  // 회사에서 크루 사전미팅 수락
  bizRejectOffer: 'biz-reject-offer',  // 회사에서 크루 사전미팅 거절
  bizNegotiateFeedback: 'biz-negotiate-feedback',  // 회사에서 계약조건 수정요청
  bizNegotiateReject: 'biz-negotiate-reject',  // 회사에서 계약 거절
  bizNegotiateAccept: 'biz-negotiate-accept',  // 회사에서 계약 수락 - 신규계약 진행
  bizSettingsProfile: 'biz-settings-profile',  // 회사정보 수정
  bizSettingsTaxbill: 'biz-settings-taxbill',  // 회사 세금계산서정보 수정
  // todo
  bizTodoHistory: 'biz-todo-history',  // 회사 투두 리스트 수정내역 보기
  bizTodoTab: 'biz-todo-tab',  // 회사 투두 리스트 미완료 탭
  bizDoneTab: 'biz-done-tab',  // 회사 투두 리스트 완료 탭
  // invitation
  bizInvitationAccept: 'biz-invitation-accept',  // 회사가 동료회사의 초대 수락
  bizInvitationLinkCopy: 'biz-invitation-link-copy',  // 10만원 할인 이벤트 초대 링크 복사
  bizInvitationNudgeOffer: 'biz-invitation-nudge-offer',  // 크루 제안 페이지에서 10만원 할인 이벤트 스낵바 클릭
  bizInvitationNudgeContract: 'biz-invitation-nudge-contract',  // 계약 페이지에서 10만원 할인 이벤트 스낵바 클릭
  bizInvitationNudgeRenewal: 'biz-invitation-nudge-renewal',  // 계약 갱신 페이지에서 10만원 할인 이벤트 스낵바 클릭
  bizInvitationLinkCopyInModal: 'biz-invitation-link-copy-in-modal',  // 스낵바->모달에서 초대 링크 복사
};

export const INPUT_ID = {
  loginEmail: 'biz-input-login-email',  // 회사 로그인 폼의 이메일 인풋
  loginPassword: 'biz-input-login-password',  // 회사 로그인 폼의 비밀번호 인풋
  registerEmail: 'biz-input-register-email',  // 회사 가입 폼의 이메일 인풋
  registerPassword: 'biz-input-register-password',  // 회사 가입 폼의 비밀번호 인풋
  newpasswordPassword: 'biz-input-newpassword-password',  // 회사 비밀번호 재설정 폼의 비밀번호 인풋
  resetpasswordEmail: 'biz-input-resetpassword-email',  // 회사 비밀번호 재설정 메일 요청 폼의 이메일 인풋
  // register-company - 가입 2단계. 회사정보 form
  registercompanyCompany: 'biz-input-registercompany-company',  // 회사 가입2 폼의 회사이름 인풋
  registercompanyName: 'biz-input-registercompany-name',  // 회사 가입2 폼의 담당자이름 인풋
  registercompanyPhone: 'biz-input-registercompany-phone',  // 회사 가입2 폼의 전화번호 인풋
  registercompanyPhonecert: 'biz-input-registercompany-phonecert',  // 회사 가입2 폼의 전화인증번호 인풋
  registercompanyServicedetail: 'biz-input-registercompany-servicedetail',  // 회사 가입2 폼의 서비스소개 인풋
  // jobpost - 공고등록 form
  jobpostPosition: 'biz-input-jobpost-position',  // 회사 공고등록 폼의 포지션 셀렉트
  jobpostAvailablefrom: 'biz-input-jobpost-availablefrom',  // 회사 공고등록 폼의 업무시작가능일 인풋
  jobpostJobintro: 'biz-input-jobpost-jobintro',  // 회사 공고등록 폼의 회사서비스 소개 인풋
  jobpostJobdetail: 'biz-input-jobpost-jobdetail',  // 회사 공고등록 폼의 담당업무 인풋
  jobpostTaskFor4weeks: 'biz-input-jobpost-taskfor4weeks',  // 회사 공고등록 폼의 4주간 할일 인풋
  jobpostSkills: 'biz-input-jobpost-skills',  // 회사 공고등록 폼의 요구스킬 인풋
  jobpostPreference: 'biz-input-jobpost-preference',  // 회사 공고등록 폼의 우대사항 인풋
  // offer
  offerRejectreason: 'biz-input-offer-rejectreason',  // 회사 매칭후보 응답 폼의 거절사유 인풋
  // negotiate
  negotiateFeedback: 'biz-input-negotiate-feedback',  // 회사 계약조건 수정 다이얼로그의 수정 요청 내용 인풋
  negotiateRejectReason: 'biz-input-negotiate-rejectreason',  // 회사 계약 거절 다이얼로그의 거절사유 인풋
};
